import React, { useState, useEffect, useRef } from 'react'
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import './BlogSection.css';
import { Card, Col, Row } from 'react-bootstrap';
import Blog1 from '../../../assets/HomePage/Blogs/Blog1.png'
import Blog2 from '../../../assets/HomePage/Blogs/Blog2.png'
import { GoDotFill } from "react-icons/go";
import { MdArrowForwardIos } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

//  Reviews Data
const reviews = [
    { name: 'User Retention: From Measuring to Improving', imageUrl: Blog1, publishedDate: 'Aug 31, 2024', readTime: "10 min", buttonText: "READ MORE", link: "codeslide-tech-news" },
    { name: 'UX Strategy: From Planning to Results', imageUrl: Blog2, publishedDate: 'Aug 12, 2024', readTime: "8 min", buttonText: "COMING SOON" },
    { name: 'Minimum Lovable Product: From Planning to Execution', imageUrl: Blog1, publishedDate: 'Aug 20, 2024', readTime: "10 min", buttonText: "COMING SOON" },
]

function BlogSection() {

    const navigate = useNavigate();

    const [isPrevDisabled, setIsPrevDisabled] = useState(true);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const carousel = useRef()

    useEffect(() => {
        const box = document.querySelector('.reviews-carousel');
        const handleScroll = () => {
            const maxScrollLeft = box.scrollWidth - box.clientWidth;
            setIsPrevDisabled(box.scrollLeft <= 0);
            setIsNextDisabled(box.scrollLeft >= maxScrollLeft);
        };
        box.addEventListener('scroll', handleScroll);
        // Run this effect once on mount
        handleScroll();
        // Cleanup event listener on component unmount
        return () => {
            box.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const calculateScrollDistance = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth > 1800) {
            return 500;
        } else if (screenWidth >= 1000) {
            return 530;
        } else if (screenWidth > 545) {
            return 540;
        } else if (screenWidth >= 545) {
            return 420;
        } else if (screenWidth >= 455) {
            return 420;
        } else if (screenWidth >= 385) {
            return 370;
        } else if (screenWidth > 365) {
            return 300;
        } else {
            return 260;
        }
    };
    const btnpressprev = () => {
        let box = document.querySelector('.reviews-carousel');
        let width = calculateScrollDistance();
        box.scrollLeft -= width;
    };

    const btnpressnext = () => {
        let box = document.querySelector('.reviews-carousel');
        let width = calculateScrollDistance();
        box.scrollLeft += width;
    };

    const handleClick = (index, link) => {
        navigate(`/${link}`, { state: { index } });
    };

    return (
        <div className='reviews-section'>
            <Row className='reviews-web-view'>
                <Col md={4} className='reviews-text'>
                    <div className='reviews-text-sec'>
                        <div className='text-arrows-container'>
                            <h2 className='home-about-heading'>Our News and Articles</h2>
                            <div className='reviews-arrows'>
                                <div className={`reviews-arrow ${isPrevDisabled ? 'disabled-icon1' : ''}`} onClick={btnpressprev}><FaAngleLeft style={{ backgroundColor: "transparent", color: 'rgb(122 122 122)', fontSize: "25px" }} /></div>
                                <div className={`reviews-arrow ${isNextDisabled ? 'disabled-icon1' : ''}`} onClick={btnpressnext}><FaAngleRight style={{ backgroundColor: "transparent", color: 'rgb(122 122 122)', fontSize: "25px" }} /></div>
                            </div>
                        </div>
                    </div>

                </Col>
                <Col className='reviews-carousel' ref={carousel}>
                    <div className='carousel-reviews'>
                        {reviews.map((review, index) => (
                            <div key={index} className='review-section'>
                                <Card className='review-cards'>
                                    <div className='reviewcard-container'>
                                        <img src={review.imageUrl} alt='blogs' />
                                        <div className='reviews-para'>{review.publishedDate} <GoDotFill /> {review.readTime} read</div>
                                        <div className='reviewcard-heading'>
                                            <h2 className='review-client'>{review.name}</h2>
                                            <div className='review-place'>{review.place}</div>
                                            <div className='review-readtext' onClick={() => handleClick(index, review.link)}>{review.buttonText} <MdArrowForwardIos style={{ marginTop: "-5px" }} /></div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default BlogSection