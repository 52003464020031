import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './WhatWeOffer1.css';

function WhatWeOffer1({carousel}) {
    const [width, setWidth] = useState(0);
    

    useEffect(() => {
        if (carousel.current) {
            setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
        }
    }, [])


    return (
        <div className='WhatWeOffer-Container'>
            <h2 className='WhatWeOffer-h3'>What We Offer</h2>
            <motion.div ref={carousel} className="carousel" >
                <motion.div
                    drag="x"
                    dragConstraints={{ right: 0, left: -width }}
                    dragElastic={0.2}  // Add slight elastic bounce
                    dragTransition={{ bounceStiffness: 300, bounceDamping: 20 }}
                    className="section-container"
                   >
                    <motion.div
                        className="category-card"
                        style={{ backgroundColor: "#B2DECA" }}
                    >
                        <h6 className='tech-sstack-heading'>Creative Design</h6>
                        <div className="tag-container">
                            <span className="tag">Logo & Brand Identity</span>
                            <span className="tag">Graphic Design</span>
                            <span className="tag">Infographics</span>
                            <span className="tag">Brochure & Print Design</span>
                            <span className="tag">Packaging Design</span>
                            <span className="tag">Social Media Posts</span>
                            <span className="tag">Banners and Posters</span>
                            <span className="tag">Display Ads</span>
                        </div>
                    </motion.div>

                    {/* UI/UX Design Card */}
                    <motion.div
                        className="category-card"
                        style={{ backgroundColor: "#ABAEDB" }}
                    >
                        <h6 className='tech-sstack-heading'>UI/UX Design</h6>
                        <div className="tag-container">
                            <span className="tag">Wireframing & Prototyping</span>
                            <span className="tag">Interaction Design</span>
                            <span className="tag">Web Design</span>
                            <span className="tag">Mobile App Design</span>
                            <span className="tag">SaaS Design</span>
                            <span className="tag">User Research</span>
                            <span className="tag">Usability Testing</span>
                        </div>
                    </motion.div>

                    {/* Full Stack Development Card */}
                    <motion.div
                        className="category-card"
                        style={{ backgroundColor: "#A8DAF4" }}
                    >
                        <h6 className='tech-sstack-heading'>Full Stack Development</h6>
                        <div className="tag-container">
                            <span className="tag">Frontend Development</span>
                            <span className="tag">Backend Development</span>
                            <span className="tag">Database Management</span>
                            <span className="tag">API Integration</span>
                            <span className="tag">Mobile Apps</span>
                            <span className="tag">Web Services</span>
                        </div>
                    </motion.div>

                    {/* Digital Marketing Card */}
                    <motion.div
                        className="category-card"
                        style={{ backgroundColor: "#DFE4C7" }}
                    >
                        <h6 className='tech-sstack-heading'>Digital Marketing</h6>
                        <div className="tag-container">
                            <span className="tag">Social Media Marketing</span>
                            <span className="tag">Content Marketing</span>
                            <span className="tag">Email Marketing</span>
                            <span className="tag">Local SEO and Marketing</span>
                            <span className="tag">Landing Pages</span>
                            <span className="tag">Market Research</span>
                            <span className="tag">SEO</span>
                            <span className="tag">PPC</span>
                            <span className="tag">Brand Development</span>
                            <span className="tag">Video Marketing</span>
                        </div>
                    </motion.div>

                    {/* Marketing Analysis Card */}
                    <motion.div
                        className="category-card"
                        style={{ backgroundColor: "#C4A8D6" }}
                    >
                        <h6 className='tech-sstack-heading'>Marketing Analysis</h6>
                        <div className="tag-container">
                            <span className="tag">Competitor Analysis</span>
                            <span className="tag">Customer Segmentation</span>
                            <span className="tag">Campaign Performance Tracking</span>
                            <span className="tag">Marketing Research</span>
                            <span className="tag">Data-Driven Insights</span>
                            <span className="tag">Presentation Templates</span>
                        </div>
                    </motion.div>
                </motion.div>
            </motion.div>
        </div>
    );
}

export default WhatWeOffer1;
