import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css';
import VideoWeb from '../../assets/HomePage/VideoWeb.mp4';
import VideoMobileVersion from '../../assets/HomePage/VideoMobile.mp4';
import OurServices from './OurServicesComponent/OurServices';
import ClientLogos from './ClientLogosComponent/ClientLogos';
import WorkWithUs from './WorkWithUsComponent/WorkWithUs';
import AboutUs from './AboutUsComponent/AboutUs';
import CardsComponent from './CardsComponent/CardsComponent';
import { Helmet } from 'react-helmet';
import Projects from './ProjectsComponent/Projects';
import Mute from '../../assets/HomePage/Mute.svg'
import Sound from '../../assets/HomePage/Sound.svg'
import Header1 from '../HeaderComponent1/Header1';
import Header from '../HeaderComponent/Header';
import HomePageVideo from '../../assets/HomePage/HomePgaeVideo.mp4'
import HomePageImage from '../../assets/HomePage/LightShadow.png'
import BlogSection from './BlogComponent/BlogSection';

function HomePage() {

  const [isMuted, setIsMuted] = useState(true);
  const [header, setHeader] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.innerWidth > 1000) {
      setHeader(true)
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      if (window.innerWidth > 1000) {
        if (scrollPosition > 800) {
          const videoElement = document.querySelector('.video1');
          videoElement.muted = true;
          setIsMuted(videoElement.muted);
        }
      } else {
        if (scrollPosition > 300) {
          const videoElement = document.querySelector('.video1');
          videoElement.muted = true;
          setIsMuted(videoElement.muted);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMute = () => {
    const videoElement = document.querySelector('.video1');
    videoElement.muted = !videoElement.muted;
    setIsMuted(videoElement.muted);
  };

  return (
    <div>
      <Helmet>
        <title>Vishesh Country Cache - Home</title>
        <link rel="canonical" href="https://visheshcountrycache.com/" />
        <meta name='description' content='Unlock top-tier software solutions with Vishesh Country Cache. Specializing in software development, testing, design, and digital marketing services. Explore opportunities now.' />
        <meta name='keywords' content='vishesh country cache Private Limited, software companies in village,software development company, top 10 software companies in india, software solutions company, software engineering companies, vacancies in small software companies for freshers, software development companies near me, 
        data software research company, software company jobs, best companies for software engineers, best software development company, internship in software company, software consulting companies, vishesh country cache software company, vishesh country cache private limited in undrajavaram, software company, 
        software company near me, vishesh software company in tanuku, software companies in tanuku, best digital marketing company near me, best digital marketing company in tanuku, website development company, best marketing analysis company in tanuku, best software company in city out cuts, top software companies in tanuku, 
        best logo design agency,logo design,best video editing near me, graphic desingers,best graphic design agencies,top marketing analytics companies best data analyst jobs,top data analytics companies,web developer jobs for freshers,jobs in web development for freshers,fornted developers near me,best backend developers near me' />
        <meta name="author" content="Vishesh Country Cache" />
        <meta name="robots" content="index, follow" />
        <html lang="en" />
      </Helmet>

      {header ? <Header1 /> : <Header />}

      {/**    Video Section       */}
      <div className="video-container">
        <div className="control-icon-div">
          {isMuted ? (
            <img src={Mute} alt="play-icons" className="control-icon" onClick={toggleMute} />
          ) : (
            <img src={Sound} alt="play-icons" className="control-icon" onClick={toggleMute} />
          )}
        </div>
        <video ref={videoRef} className='video1' loop playsInline disablePictureInPicture preload="metadata" autoPlay muted>
          {window.innerWidth >= 1600 ? <source src={VideoWeb} type='video/mp4' /> :
            <source src={VideoMobileVersion} type='video/mp4' />}
          <meta itemprop="name" content="visheshHome.mp4" />
        </video>
      </div>

      {/**    Web view view       */}
      <div className='home-main-section'>
        <div className="home-container">
          <h2 className='home-main-heading'>Turning Technological</h2>

          <div className='home-heading-video'>
            <div className='video-container11'>
              <video className='video-homepage' loop playsInline disablePictureInPicture preload="metadata" autoPlay muted>
                <source src={HomePageVideo} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <h2 className='home-main-heading'> Needs</h2>
          <h2 className='home-main-heading'> into</h2>

          <div className='home-heading-video'>
            <img src={HomePageImage} className='homepage-image' alt="homepagevideo" />
          </div>

          <h2 className='home-main-heading'>Enduring Realities</h2>
        </div>
        <p className='home-section-para'>Unlock top-tier software solutions with Vishesh Country Cache. Specializing in software development, testing, design, and digital marketing services.</p>
      </div>
      {/**    Mobile view         */}
      <div className='home-main-section1'>
        <h2 className='home-main-heading'>Turning Technological Needs into Enduring Realities</h2>
        <p className='home-section-para'>Unlock top-tier software solutions with Vishesh Country Cache. Specializing in software development, testing, design, and digital marketing services.</p>
      </div>

      {/**    Our Services Section */}
      <OurServices />

      {/**    About Us Section     */}
      <AboutUs />

      {/**    Why Us Cards Section */}
      <CardsComponent />

      {/**    logo slider          */}
      <ClientLogos />

      {/* Featured Projects Section  */}
      <Projects />

      {/* Blog Section  */}
      <BlogSection />

      {/**    Work with us section         */}
      <WorkWithUs />

    </div>
  );
}

export default HomePage;
