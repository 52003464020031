import React, { useState, useEffect, useRef } from "react";
import "./BlogPage.css";
import blog_img from '../../assets/HomePage/Blogs/Blog1.png'
import { GoDotFill } from "react-icons/go";
import { Col, Row } from "react-bootstrap";
import Header1 from '../HeaderComponent1/Header1';
import Header from '../HeaderComponent/Header';
import { useLocation } from "react-router-dom";


const data = [
    {
        title: "How Fonts Influence Emotions: The Psychology of Typography",
        description:
            "When you think about branding or marketing, fonts might not be the first thing that comes to mind. However, typography plays a much bigger role in shaping how we perceive brands and products than many realize. Fonts aren't just about making words legible—they evoke emotions and influence the way we interpret messages. In fact, the psychology of typography is a powerful tool in both design and branding. Let's explore how fonts influence emotions and why choosing the right one is critical for your brand’s success.",
        content: [
            {
                title: "Measuring",
                description: "You can’t get away with just attracting new customers. Your strategic goal is to keep them engaged and loyal. In this article, we will go through the techniques we employ to measure and improve retention for our clients. Let’s dive right in.",
                subDescription: ""
            },
            {
                title: "How do you know it is good?",
                description:
                    "You can’t get away with just attracting new customers. Your strategic goal is to keep them engaged and loyal. In this article, we will go through the techniques we employ to measure and improve retention for our clients. Let’s dive right in.",
                subDescription: ""
            },
            {
                title: "Conclusion",
                description:
                    "You can’t get away with just attracting new customers. Your strategic goal is to keep them engaged and loyal. In this article, we will go through the techniques we employ to measure and improve retention for our clients. Let’s dive right in.",
                subDescription: "",
            }]
    }
];


function BlogPage() {
    const location = useLocation();
    const index = location.state?.index || 0;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedBlog, setSelectedBlog] = useState(data[index < data.length ? index || 0 : 0]);
    const detailsRef = useRef(null);
    const [header, setHeader] = useState(false);
    const countingRef = useRef([]);
    const setRef = (element, index) => {
        countingRef.current[index] = element;
    };
    const [isIntersecting, setIsIntersecting] = useState([]);
    const handleIntersection = (entries) => {
        const newVisibility = [...isIntersecting];

        entries.forEach(entry => {
            const index = countingRef.current.indexOf(entry.target);
            if (index !== -1) {
                newVisibility[index] = entry.isIntersecting;
            }
        });

        setIsIntersecting(newVisibility);
    };
    useEffect(() => {
        const options = {
            threshold: 0.5, // Adjust as needed
        };
        const countRef = countingRef
        const observer = new IntersectionObserver(handleIntersection, options);

        countRef.current.forEach(ref => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            // Cleanup
            countRef.current.forEach(ref => {
                if (ref) {
                    observer.unobserve(ref);
                }
            });
        };

    }, []);

    useEffect(() => {
        // window.scrollTo(0, 0);

        if (window.innerWidth > 1000) {
            setHeader(true)
        }
        // handleScrollTo(isIntersecting.length-1)
        console.log("view :", isIntersecting.length)

    }, [isIntersecting])

    // Handle scroll to selected description
    const handleScrollTo = (index) => {
        setSelectedIndex(index);
    };

    // Handle manual scroll to update the selectedIndex
    const handleScroll = () => {
        if (detailsRef.current) {
            const container = detailsRef.current;
            const descriptions = Array.from(
                container.querySelectorAll(".description-item")
            );
            const scrollTop = container.scrollTop;
            for (let i = 0; i < descriptions.length; i++) {
                const description = descriptions[i];
                if (description.offsetTop >= scrollTop) {
                    setSelectedIndex(i);
                    break;
                }
            }
        }
    };

    return (
        <>
            {header ? <Header1 /> : <Header />}
            <div className="image-text-row">
                <img src={blog_img} alt="Blog" className="blog-image-sec" />
                <h4 className="blog-title">{selectedBlog.title}</h4>
            </div>
            <div className="text-container">
                <div className='reviews-para bolg-page-text'>{selectedBlog.publishedDate} <GoDotFill style={{ fontSize: 12 }} /> {selectedBlog.readTime} read</div>
            </div>
            <div className="details-body">
                <div>
                    <Row className="carousel-row">
                        <Col xs={12} md={4} className="carousel-container">
                            <div className="titles-container">
                                <p className="content_heading">Content:</p>
                                {selectedBlog.content.map((item, index) => (
                                    <a href={`#Content${index}`} style={{ textDecoration: "none", color: '#33353a', width: 300, backgroundColor: "red" }} key={index}>
                                        <div className={`title-item  ${selectedIndex === index ? "active" : ""}`}
                                            onClick={() => { handleScrollTo(index); }} >
                                            - {item.title}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </Col>
                        <Col
                            xs={12}
                            md={8}
                            className="carousel-details"
                            ref={detailsRef}
                            onScroll={handleScroll}
                        >
                            <p className="sub-description" style={{ marginBottom: "2rem" }}>{selectedBlog.description}</p>
                            {selectedBlog.content.map((item, index) => (
                                <div className="details-container" ref={(element) => setRef(element, index)} >
                                    <div
                                        key={index}
                                        id={`description-${index}`}
                                        className={`description-item ${"active-description"}`}
                                    >
                                        <h6 className="description-heading" id={`Content${index}`}>{item.title}</h6>
                                        <p className="sub-description">{item.description}</p>
                                        <p className="sub-description">{item.subDescription}</p>
                                    </div>
                                </div>
                            ))}

                        </Col>
                    </Row>

                </div>
            </div>
        </>
    );
}

export default BlogPage