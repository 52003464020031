import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CookieConsent, { Cookies } from "react-cookie-consent";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Reject"
      cookieName="myAwesomeCookieName2"
      style={{ background: "#F7F5F2" }}
      buttonStyle={{ color: "#000", padding: "15px 18px" }}
      declineButtonStyle={{ color: "#000", background: "#ccc", padding: "15px 18px" }}
      enableDeclineButton
      onDecline={() => {
        Cookies.remove('myAwesomeCookieName2');
        console.log("Cookies have been rejected.");
      }}
      expires={150}
    >
      <h6 className='cookie-setting-heading'>Cookies Settings</h6>
      <p className='cookie-sec-text'>
        By clicking “Accept”, you agree to the storing of cookies on your device to enhance site navigation, analyse site usage, and assist in our marketing efforts. View our <a href='/privacy-policy' className='cookie-sec-policy'>Privacy Policy</a> for more information.
      </p>
    </CookieConsent>
    <App />
  </React.StrictMode>
);