import React, { useEffect ,useRef, useState} from "react";
import "./ServicesPage.css";
import Principles from "./Principles/principles";
import { Helmet } from "react-helmet";
import ToolsToggle from "./ToolsToggle/ToolsToggle";
import WhatWeOffer from "./WhatWeOffer/WhatWeOffer";
import WorkFlowHover from "./WorkFlowHoverComponent/WorkFlowHover";
import Header from '../HeaderComponent/Header'
import WhatWeOffer1 from "./WhatWeOffer1/WhatWeOffer1";

function ServicesPage() {
  const scrollContainerRef = useRef(null);
  const [hrScrollValue,setHrScrollValue]=useState(0);
  const [scrollBottom,setScrollBottom]=useState(false)
  const [scrollCorPos,setScrollCorPos]=useState(0)
  useEffect(() => {
    window.scrollTo(0, 0);
    if (carousel.current) {
    setHrScrollValue(carousel.current.scrollLeft)
    }
    window.addEventListener('scroll', (event) => {
      const scrollTop = window.scrollY; // Current scroll position from the top
      const scrollHeight = document.body.scrollHeight; // Total height of the document
      const clientHeight = window.innerHeight; // Height of the visible viewport
  
      const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
      const scrollHor=scrollTop
      if (carousel.current) {
        
          carousel.current.scrollLeft = hrScrollValue + scrollHor;
          setScrollCorPos(hrScrollValue + scrollHor)
        
        scrollContainerRef.current.scrollTop = 0;

        const scrollLeft = carousel.current.scrollLeft;
        const scrollWidth = carousel.current.scrollWidth;
        const clientWidth = carousel.current.clientWidth;
        if (scrollLeft + clientWidth >= scrollWidth) {
          // alert('You have reached the end of the carousel!');
          setScrollBottom(true);
        }
        else{
          setScrollBottom(false)
        }
      }
     
      console.warn(`Scroll Top: ${scrollTop}`);
      console.warn(`Scroll Percentage: ${scrollPercentage.toFixed(2)}%`);
  });
  }, []);

  useEffect(()=>{
    if(scrollBottom){
      // window.scrollTo(0, scrollCorPos)
    }
  },[scrollBottom])

  const carousel = useRef();


  return (
    <div ref={scrollContainerRef} >
      <Helmet>
        <title>Vishesh Country Cache - Services</title>
        <link rel="canonical" href="https://visheshcountrycache.com/services" />
        <meta name='description' content='Unlock top-tier software solutions with Vishesh Country Cache. Specializing in software development, testing, design, and digital marketing services. Explore opportunities now.' />
        <meta name='keywords' content='vishesh country cache Private Limited, software companies in village,software development company, top 10 software companies in india, software solutions company, software engineering companies, vacancies in small software companies for freshers, software development companies near me, 
        data software research company, software company jobs, best companies for software engineers, best software development company, internship in software company, software consulting companies, vishesh country cache software company, vishesh country cache private limited in undrajavaram, software company, 
        software company near me, vishesh software company in tanuku, software companies in tanuku, best digital marketing company near me, best digital marketing company in tanuku, website development company, best marketing analysis company in tanuku, best software company in city out cuts, top software companies in tanuku, 
        best logo design agency,logo design,best video editing near me, graphic desingers,best graphic design agencies,top marketing analytics companies best data analyst jobs,top data analytics companies,web developer jobs for freshers,jobs in web development for freshers,fornted developers near me,best backend developers near me' />
        <meta name="author" content="Vishesh Country Cache" />
        <meta name="robots" content="index, follow" />
        <html lang="en" />
      </Helmet>

     
     


      <div style={
        {height:window.innerHeight+window.innerHeight+window.innerHeight,backgroundColor:"transparent"} 
       } >
       <div style={{position:"sticky",top:0 ,transition:"1s",zIndex:0}}>
       <Header />
        <WhatWeOffer1 carousel={carousel}/>
        </div>
       </div>
     
      <div style={{position:"relative",zIndex:1}} > 
      <div style={{ margin: '3% 3%' }}>
        <p className="home-project-text" style={{ textAlign: "left" }}>
        Whether you have an existing tech stack or need a new one, our team is ready to handle your project with the right skills and tools. 
        </p>
      </div>
      <ToolsToggle />
      <h3 className="heading-work">Workflow</h3>
      <div>
        <WorkFlowHover />
      </div>
      <div>
        <Principles />
      </div>{" "}
      </div>
    </div>
  );
}

export default ServicesPage;
