import React, { useState, useEffect, useRef } from "react";
import "./BlogPage.css";
import blog_img from '../../assets/HomePage/Blogs/Blog1.png'
import { GoDotFill } from "react-icons/go";
import { Col, Row } from "react-bootstrap";
import Header1 from '../HeaderComponent1/Header1';
import Header from '../HeaderComponent/Header';
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const data = [
  {
    title: "How Fonts Influence Emotions: The Psychology of Typography",
    description:
      "This version is more direct and easier to read. With advancements in AI, software, and new gadgets, staying informed is essential. CodeSlide Tech News is your go-to source for fresh, up-to-date tech news, providing insights that shape the future of technology. ",
    publishedDate: 'Aug 31, 2024', readTime: "10 min",
    content: [
      {
        title: "Why Follow CodeSlide Tech News?",
        description: "Imagine visiting a website for a law firm that uses a playful, whimsical font. It might make you question the firm’s professionalism. On the other hand, if a children’s toy brand uses a rigid, formal font, it may feel unapproachable or overly serious. This is why font selection is so critical: it sets the tone for how consumers view your brand and whether they feel comfortable engaging with it.",
        subPointHead1: "Software Development:",
        subPointHead2: "Artificial Intelligence & Machine Learning:",
        subPointHead3: "Cybersecurity:",
        subPointHead4: "Gadgets and Innovations:",
        subPointHead5: "Cloud Computing & Big Data:",
        subPoint1: "Stay updated with the latest frameworks, programming languages, and development best practices.",
        subPoint2: "Explore AI breakthroughs changing industries and our daily lives.",
        subPoint3: "Protect your digital assets by staying informed about the latest security threats and solutions.",
        subPoint4: "From smartphones to smart homes, learn about the coolest gadgets and how they’re shaping the future.",
        subPoint5: "Understand the tools and technologies driving business growth and data-driven decision-making."
      },
      {
        title: "Top Features of CodeSlide Tech News",
        subPoint1: "Real-Time Updates",
        subPoint2: "The tech world moves quickly. With our real-time news updates, you’ll never miss out on crucial tech stories that can impact your work, investments, or interests.",
        subPoint3: "Expert Insights",
        subPoint4: "Our experts turn complex tech trends into easy-to-read articles, helping you stay informed and act.",
        subPoint5: "In-Depth Analyses",
        subPoint6: "We cover the latest headlines and offer detailed analysis and expert views on new technologies and trends.",
        subPoint7: "Curated for Developers and Tech Enthusiasts",
        subPoint8: "Whether you're a software engineer, entrepreneur, or tech enthusiast, CodeSlide offers tutorials and guides to help you navigate technology."
      },
      {
        title: "Latest Topics You’ll Find on CodeSlide",
        subPointHead1: "AI and Chatbots:",
        subPointHead2: "Blockchain and Cryptocurrency:",
        subPointHead3: "5G and IoT:",
        subPointHead4: "Quantum Computing:",
        subPoint1: "Explore how chatbots are becoming smarter and their role in customer service, sales, and automation.",
        subPoint2: "Explore the latest in blockchain, digital currencies, and their impact on global finance.",
        subPoint3: "Discover 5G advancements and the expanding reach of IoT, connecting devices like never before.",
        subPoint4: "Stay informed on quantum computing and its potential to transform industries."
      }]
  }
];


function BlogDetailsPage() {
  const location = useLocation();
  const index = location.state?.index || 0;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedBlog, setSelectedBlog] = useState(data[index < data.length ? index || 0 : 0]);
  const detailsRef = useRef(null);
  const [header, setHeader] = useState(false);
  const countingRef = useRef([]);
  const setRef = (element, index) => {
    countingRef.current[index] = element;
  };
  const [isIntersecting, setIsIntersecting] = useState([]);
  const handleIntersection = (entries) => {
    const newVisibility = [...isIntersecting];

    entries.forEach(entry => {
      const index = countingRef.current.indexOf(entry.target);
      if (index !== -1) {
        newVisibility[index] = entry.isIntersecting;
      }
    });

    setIsIntersecting(newVisibility);
  };
  useEffect(() => {
    const options = {
      threshold: 0.5, // Adjust as needed
    };
    const countRef = countingRef
    const observer = new IntersectionObserver(handleIntersection, options);

    countRef.current.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      // Cleanup
      countRef.current.forEach(ref => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };

  }, []);

  useEffect(() => {
    // window.scrollTo(0, 0);

    if (window.innerWidth > 1000) {
      setHeader(true)
    }
    // handleScrollTo(isIntersecting.length-1)
    console.log("view :", isIntersecting.length)

  }, [isIntersecting])

  // Handle scroll to selected description
  const handleScrollTo = (index) => {
    setSelectedIndex(index);
  };

  // Handle manual scroll to update the selectedIndex
  const handleScroll = () => {
    if (detailsRef.current) {
      const container = detailsRef.current;
      const descriptions = Array.from(
        container.querySelectorAll(".description-item")
      );
      const scrollTop = container.scrollTop;
      for (let i = 0; i < descriptions.length; i++) {
        const description = descriptions[i];
        if (description.offsetTop >= scrollTop) {
          setSelectedIndex(i);
          break;
        }
      }
    }
  };

  return (
    <>
    <Helmet>
    <title>CodeSlide Tech News</title>
    <link rel="canonical" href="https://visheshcountrycache.com/codeslide-tech-news" />
    <meta name='description' content='Stay updated with the latest tech news and advancements in coding. Discover insightful articles and tutorials with code slides to boost your programming skills on our platform ' />
    <meta name='keywords' content='codeslide tech news,tech news, vishesh country cache Private Limited, software companies in village,software development company, top 10 software companies in india, software solutions company, software engineering companies, vacancies in small software companies for freshers, software development companies near me, 
    data software research company, software company jobs, best companies for software engineers, best software development company, internship in software company, software consulting companies, vishesh country cache software company, vishesh country cache private limited in undrajavaram, software company, 
    software company near me, vishesh software company in tanuku, software companies in tanuku, best digital marketing company near me, best digital marketing company in tanuku, website development company, best marketing analysis company in tanuku, best software company in city out cuts, top software companies in tanuku, 
    best logo design agency,logo design,best video editing near me, graphic desingers,best graphic design agencies,top marketing analytics companies best data analyst jobs,top data analytics companies,web developer jobs for freshers,jobs in web development for freshers,fornted developers near me,best backend developers near me' />
    <meta name="author" content="Vishesh Country Cache" />
    <meta name="robots" content="index, follow" />
    <html lang="en" />
  </Helmet>
      {header ? <Header1 /> : <Header />}
      <div className="image-text-row">
        <img src={blog_img} alt="Blog" className="blog-image-sec" />
        <h4 className="blog-title">Stay Updated with the Latest in Tech- CodeSlide Tech News</h4>
        <div className='reviews-para bolg-page-text'>{selectedBlog.publishedDate} <GoDotFill style={{ fontSize: 12, background: "transparent" }} /> {selectedBlog.readTime} read</div>
      </div>
      <div className="details-body">
        <div>
          <Row className="carousel-row">
            <Col xs={12} md={4} className="carousel-container">
              <div className="titles-container">
                {selectedBlog.content.map((item, index) => (
                  <a href={`#Content${index}`} style={{ textDecoration: "none", color: '#33353a', width: 300, backgroundColor: "red" }} key={index}>
                    <div className={`title-item  ${selectedIndex === index ? "active" : ""}`}
                      onClick={() => { handleScrollTo(index); }} >
                      {item.title}
                    </div>
                  </a>
                ))}
              </div>
            </Col>
            <Col xs={12} md={8} className="carousel-details" ref={detailsRef} onScroll={handleScroll}>
              <p className="sub-description" style={{ marginBottom: "2rem" }}>{selectedBlog.description}</p>
              {selectedBlog.content.map((item, index) => (
                <div className="details-container" ref={(element) => setRef(element, index)} >
                  <div
                    key={index}
                    id={`description-${index}`}
                    className={`description-item ${"active-description"}`}
                  >
                    <h6 className="description-heading" id={`Content${index}`}>{item.title}</h6>
                    <p className="sub-description">{item.description}</p>
                    <ul style={{ background: "transparent" }}>
                      {item.subPoint1 && (
                        <li className="item-list-sunpoint">
                          <span style={{ fontWeight: "bold", background: "transparent" }}>
                            {item.subPointHead1}
                          </span> {item.subPoint1}
                        </li>
                      )}
                      {item.subPoint2 && (
                        <li className="item-list-sunpoint">
                          <span style={{ fontWeight: "bold", background: "transparent" }}>
                            {item.subPointHead2}
                          </span> {item.subPoint2}
                        </li>
                      )}
                      {item.subPoint3 && (
                        <li className="item-list-sunpoint">
                          <span style={{ fontWeight: "bold", background: "transparent" }}>
                            {item.subPointHead3}
                          </span> {item.subPoint3}
                        </li>
                      )}
                      {item.subPoint4 && (
                        <li className="item-list-sunpoint">
                          <span style={{ fontWeight: "bold", background: "transparent" }}>
                            {item.subPointHead4}
                          </span> {item.subPoint4}
                        </li>
                      )}
                      {item.subPoint5 && (
                        <li className="item-list-sunpoint">
                          <span style={{ fontWeight: "bold", background: "transparent" }}>
                            {item.subPointHead5}
                          </span> {item.subPoint5}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              ))}
            </Col>
          </Row>

        </div>
      </div>
    </>
  );
}

export default BlogDetailsPage